import React from 'react';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import size from 'lodash/size';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import map from 'lodash/map';
import {useSelector} from 'react-redux';

import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import apps from '@apps';
import startCase from 'lodash/startCase';

import useStyles from './styles';


export default function MiniDrawer(props) {
    const {handleDrawerClose, open} = props;
	const classes = useStyles();
	const theme = useTheme();
    const history = useHistory();
    const { accountImpersonated } = useSelector(state => state.app);
    const { user } = useSelector(state => state.auth);
    const [activeCollapse, setActiveCollapse] = React.useState({});

    const getRoutes = () => {
        let appRoutes = [];
        if (accountImpersonated.id || size(user.account_id)) {
            appRoutes = Object.values(apps.impersonatedRoutes)
        } else appRoutes = Object.values(apps.private);

        return filter(appRoutes, item => item.showNavigation)
    };

    const handleRouteClick = (currentPath) => {
        const route = currentPath.path[0];
        if (currentPath.nestedNavigation){
            setActiveCollapse(currentPath)
        } else {
            history.push(route);
        }
    };

    const handleNestedNavigation = (nestedPath) => {
        const query = `${nestedPath.filterType}=${nestedPath.filterParams}`
        history.push(`${activeCollapse.path[0]}?${query}`);
        // history.push(`${activeCollapse.path[0]}/${nestedPath.filterParams}`);
    }

    const renderCollapseIcon = (path) => {
        if (path.nestedNavigation){
            return activeCollapse.label === path.label ? <ExpandLess /> : <ExpandMore />
        }
        return null;
    }

    const renderNestedPath = (path,index) => {
        return (
            <ListItem className={classes.routesList} onClick={() => handleNestedNavigation(path)} key={index} button className={classes.nested}>
                <ListItemIcon className={classes.appIcon}>
                    <StarBorder />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.mainText }} primary={startCase(path.title)} />
            </ListItem>
        )
    }

	return (
		<Drawer
			variant="permanent"
            className={clsx(classes.drawer,
                classes.mainDrawer,
                {
				    [classes.drawerOpen]: open,
				    [classes.drawerClose]: !open,
                }
            )}
			classes={{
                paperAnchorDockedLeft: classes.noShadow,
                paper: clsx(classes.mainDrawer,{
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			<div className={classes.toolbar}>
                <img src='https://res.cloudinary.com/brainbout/image/upload/v1615203829/bbLogo_dogqj2.png' className={classes.brandLogo} />
				<IconButton onClick={handleDrawerClose}>
					{theme.direction === 'rtl' ?
                        <ChevronRightIcon className={classes.drawerIcon} /> :
                        <ChevronLeftIcon className={classes.drawerIcon} />
                    }
				</IconButton>
			</div>

			<List style={{ overflowY: 'scroll'}}>
                {getRoutes().map((currentPath, index) => {
                    const route = currentPath.path[0];
                    const isActiveRoute = includes(history.location.pathname, currentPath.path)

                    return (
                        <React.Fragment key={index}>
                            <ListItem 
                                dense
                                selected={isActiveRoute}
                                className={clsx(classes.routesList, { [classes.highlight]: isActiveRoute})} 
                                onClick={() => handleRouteClick(currentPath)} button>
                                <ListItemIcon className={classes.appIcon}>
                                    {currentPath.icon}
                                </ListItemIcon>
                                <ListItemText 
                                    primary={currentPath.label} 
                                    classes={{primary: classes.mainText}}
                                >
                                    {startCase(route)}
                                </ListItemText>
                                {renderCollapseIcon(currentPath)}
                            </ListItem>

                            <Collapse in={activeCollapse.label === currentPath.label} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {map(currentPath.nestedNavigation, renderNestedPath)}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    );
                })}
			</List>
		</Drawer>
	);
}
