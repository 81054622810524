import { ThemeOptions } from "@material-ui/core/styles";
import { red, orange, deepOrange } from "@material-ui/core/colors";

export const appLightPalette = {
    primary: {
        main: "#2b2e64",
    },
    secondary: {
        main: "#a2258d",
        300: "#c3abd9",
        600: "#8758B3",
        800: "#43295b",
    },
    error: {
        main: red.A400,
        800: red.A700,
    },
    background: {
        default: "#fff",
    },
    grey: {
        300: "#494D5F",
        400: "#A8ACAA",
        mild: "#fffcfc",
    },

    info: {
        main: "#4460E1",
        100: "#e8f0fe",
    },
    warning: {
        main: deepOrange.A400,
    },
};

export const appLightColors = {
    grey: "#B8BECD",
    green: "#5EC994",
    white: "#fff",
    drawerText: '#2b2e64',
    textLight: '#484848',
    darkest: 'black',
    e4m: '#a2258d',
    background: '#f8f8f8',
    lightGrey: '#f8f8f8',
    lightest: '#fff',
    drawer: '#fff',
    tableBg: '#fff',
    tableBorder: 'rgba(224, 224, 224, 1)',
    paperBorder: '#e4e9f0',
    optionColor: '#353434cf',
    paperBoxShadow: '0 4px 10px 0 rgb(20 19 34 / 3%), 0 0 10px 0 rgb(20 19 34 / 2%)',
};
