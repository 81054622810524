const baseUrl = process.env.BACKEND_BASE_URL;

export const BACKEND_URL = `${baseUrl}/api/v1`;


export const USER_LOGIN = `${baseUrl}/api/v1/auth/login`;
export const UPLOAD_FILE = `${baseUrl}/api/v1/upload`;

export const CORPORATE_LIST = `${baseUrl}/api/v1/account`;
export const CORPORATE_ADD = `${baseUrl}/api/v1/account`;
export const CORPORATE_UPDATE = `${baseUrl}/api/v1/account`;
export const CORPORATE_DELETE = `${baseUrl}/api/v1/account`;


export const LOCATION_LIST = `${baseUrl}/api/v1/location`;
export const LOCATION_ADD = `${baseUrl}/api/v1/location`;
export const LOCATION_UPDATE = `${baseUrl}/api/v1/location`;
export const LOCATION_DELETE = `${baseUrl}/api/v1/location`;



export const CATEGORY_LIST = `${baseUrl}/api/v1/genre`;
export const CATEGORY_ADD = `${baseUrl}/api/v1/genre`;
export const CATEGORY_UPDATE = `${baseUrl}/api/v1/genre`;
export const CATEGORY_DELETE = `${baseUrl}/api/v1/genre`;


export const UPLOAD_LIST = `${baseUrl}/api/v1/upload`;
export const UPLOAD_ADD = `${baseUrl}/api/v1/upload`;
export const UPLOAD_UPDATE = `${baseUrl}/api/v1/upload`;
export const UPLOAD_DELETE = `${baseUrl}/api/v1/upload`;

export const ANNOUNCEMENT_LIST = `${baseUrl}/api/v1/announcement`;
export const ANNOUNCEMENT_ADD = `${baseUrl}/api/v1/announcement`;
export const ANNOUNCEMENT_UPDATE = `${baseUrl}/api/v1/announcement`;
export const ANNOUNCEMENT_DELETE = `${baseUrl}/api/v1/announcement`;


export const QUESTION_LIST = `${baseUrl}/api/v1/question`;
export const QUESTION_ADD = `${baseUrl}/api/v1/question`;
export const QUESTION_UPDATE = `${baseUrl}/api/v1/question`;
export const QUESTION_DELETE = `${baseUrl}/api/v1/question`;
export const QUESTION_BATCH_UPLOAD = `${baseUrl}/api/v1/question/batch`;


export const EVENT = `${baseUrl}/api/v1/event`;
export const COMPETITION = `${baseUrl}/api/v1/competition`;
export const PARTICIPATION = `${baseUrl}/api/v1/participation`;
export const EVENT_LEADERBOARD = `${baseUrl}/api/v1/event`;

export const EVENT_LIST = `${baseUrl}/api/v1/event`;
export const EVENT_ADD = `${baseUrl}/api/v1/event`;
export const EVENT_UPDATE = `${baseUrl}/api/v1/event`;
export const EVENT_DELETE = `${baseUrl}/api/v1/event`;


export const USER_LIST = `${baseUrl}/api/v1/accountuser`;
export const USER_ADD = `${baseUrl}/api/v1/accountuser`;
export const USER_UPDATE = `${baseUrl}/api/v1/accountuser`;
export const USER_DELETE = `${baseUrl}/api/v1/accountuser`;

export const MEDIA = `${baseUrl}/api/v1/upload`
export const FEEDBACK = `${baseUrl}/api/v1/contactus`


//download
export const USER_DOWNLOAD = `${baseUrl}/api/v1/accountuser/download`;
export const CATEGORY_DOWNLOAD = `${baseUrl}/api/v1/genre/download`;
export const QUESTION_DOWNLOAD = `${baseUrl}/api/v1/question/download`;
export const PARTICIPATION_DOWNLOAD = `${baseUrl}/api/v1/participation/download`;
